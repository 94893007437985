import React, {useEffect, useState} from "react";
import styles from "./alertBox.module.css";
import {HelpOutline, Launch} from "@material-ui/icons";
import {Button, ClickAwayListener, Popover, Tooltip, Typography} from "@material-ui/core";
import {Link} from "react-router-dom";

type Props = {
    msg?: string;
};

const AlertBox = (props: Props) => {

    const [visible, setVisible] = useState(true);
    const [tooltipVisible, setTooltipVisible] = useState(false);
    const [anchorEl, setAnchorEl] = useState(null);

    const closeAlert = () => {
        setVisible(false);
    };

    const showPopup = (event: any) => {
        setTooltipVisible(true)
        setAnchorEl(event.currentTarget);
    };

    function hidePopup() {
        setTooltipVisible(false)
    }

    const evalueringUrl = "https://forms.office.com/Pages/ResponsePage.aspx?id=fZeG_emGJ0-rpklpMYA3VlGN7XFPm6pKhzWEPEX7VCxUQ1U3SVNZTFZMTEZWUFpITDNRRldWTDAzSi4u";
    if (props.msg && props.msg.trim().length > 0 && visible) {
        return (
            <div className={styles.container} >

                <span onClick={showPopup} className={styles.popLink}>
                    {props.msg}
                </span>
                <Popover
                    id={"popId"}
                    open={tooltipVisible}
                    anchorEl={anchorEl}
                    onClose={hidePopup}
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'center',
                    }}
                    transformOrigin={{
                        vertical: 'top',
                        horizontal: 'center',
                    }}
                >
                    <div className={styles.popupBox}>
                        <div>
                            Siden 2021 har koordinatorene i FNF publisert relevante høringer for natur- og friluftslivsorganisasjonene på høringsradaren.no. Nå er vi i gang med en evaluering av flere sider ved tjenesten. Målet er å forbedre brukeropplevelsen for brukerne av nettsiden. Spørreskjemaet er anonymt og for intern bruk i FNF til utvikling av høringsradaren.no.
                        </div>

                        <a href={evalueringUrl} target="_blank" rel="noopener noreferrer">
                            Gå til evalueringen<Launch/>
                        </a>
                    </div>
                </Popover>

                 {/*<span onClick={closeAlert} className={styles.closeAlert}/>*/}

            </div>
        );
    } else {
        return null;
    }
};

export default AlertBox;

